<template>
  <div
    v-if="!!(Filters && Filters.items)"
    class="button-filters-container"
  >
    <div class="all-filters-container">
      <button
        v-for="filter in filterItems"
        :key="filter.id"
        class="text-capitalize"
        @click="onFilterClick(filter)"
        type="button"
      >
        <h5
          :class="filter.id === currentFilter || filter.title === currentFilter  ? '-sci-gradient' : ''"
          class="h5"
        >
          {{ filter.title }}
        </h5>
      </button>
    </div>
  </div>
</template>

<script>
import GetFiltersQuery from '@/graphql/ListCategory.gql'

export default {
  name: 'ButtonFilters',
  apollo: {
    Filters: {
      query: GetFiltersQuery,
      variables: {
        sortOn: '_slug',
        descending: false
      },
      async result () {
        this.filterItems = this.filterItems.concat(this.Filters.items)
      }
    }
  },
  props: {
    selectedFilter: {
      type: [Number, String]
    }
  },
  data: () => ({
    filterItems: [Object.freeze({ title: 'All', id: -1 })],
    currentFilter: -1
  }),
  methods: {
    onFilterClick (filter) {
      this.$emit('on-filter-click', filter)
    }
  },
  watch: {
    selectedFilter: function (value) {
      this.currentFilter = value
    }
  }
}
</script>

<style lang="scss" scoped>
.button-filters-container {
  height: rem(78px);

  @include breakpoint ('sm-and-down') {
    margin-top: rem(28px);
  }
}

.all-filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

button {
  text-decoration: none;
  outline: none;
}
</style>
