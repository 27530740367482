<template>
  <v-container class="empty-list-container">
    <v-row class="empty-list-row">
      <v-col cols="12" sm="8" offset-sm="2" class="text-center">
        <p class="mb-8">
          We have no {{ filterTitle && filterTitle !== 'All' ? `"${filterTitle}"` : '' }}
          products available at the moment {{ search ? `for "${search}"` : '' }}
        </p>
        <Cta
          label="Show me all products"
          action="button"
          to=""
          @click.prevent="$emit('on-reset-filters')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Cta from './Cta.vue'

export default {
  components: { Cta },
  name: 'FiltersEmptyState',
  props: {
    search: {
      type: String,
      required: false
    },
    filterTitle: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-list-container {
  @include breakpoint ('sm-and-down') {
    padding: 0 rem(10px);
  }
}

.empty-list-row {
  padding-top: rem(88px);
  @include breakpoint ('sm-and-down') {
    padding-top: rem(65px);
  }
}
</style>
