<template>
  <div>
    <v-fade-transition mode="out-in">
      <v-container
        v-if="cards.length"
        class="cards-list-container"
      >
        <v-row class="cards-list-row">
          <v-col
            cols="12"
            sm="8"
            offset-sm="2"
          >
            <transition-group name="flip-list" tag="div" class="row">
              <v-col
                v-for="card in cards"
                :key="card.id"
                cols="6"
                md="4"
                class="flip-list-item translate-y pa-0"
              >
                <Card
                  v-bind="card"
                  :type="card.__typename"
                />
              </v-col>
            </transition-group>
          </v-col>
        </v-row>
      </v-container>
      <FiltersEmptyState
        v-else
        :filterTitle="filterTitle"
        :search="search"
        @on-reset-filters="$emit('on-reset-filters')"
      />
    </v-fade-transition>
  </div>
</template>

<script>
import Card from '@/atoms/Card.vue'
import FiltersEmptyState from '@/molecules/FiltersEmptyState.vue'

export default {
  name: 'CardList',
  props: {
    cards: {
      type: Array,
      required: true
    },
    search: {
      type: String,
      required: false
    },
    filterTitle: {
      type: String,
      required: false
    }
  },
  components: {
    Card,
    FiltersEmptyState
  }
}
</script>

<style lang="scss" scoped>

$card-translatey: 38px;

.row {
  position: relative;
}

.cards-list-container {
  @include breakpoint ('sm-and-down') {
    padding: 0 rem(10px);
  }
}

.cards-list-row {
  padding-bottom: rem($card-translatey*2);
  padding-top: rem(88px);
  @include breakpoint ('sm-and-down') {
    padding-top: rem(65px);
  }
}

.flip-list-item {
  transition: all 0.5s;
}

.flip-list-enter,
.flip-list-leave-to {
  opacity: 0;
  transform: translateY($card-translatey);
}

.flip-list-leave-active {
  position: absolute;
}

.translate-y {
  &:nth-child(3n-7) {
    transform: translateY(rem($card-translatey));
    @include breakpoint ('sm-and-down') {
      transform: translateY(0);
    }
  }

  &:nth-child(odd) {
    .card-container {
      transform: translateY(0);
      @include breakpoint ('sm-and-down') {
        transform: translateY(rem(-1 * $card-translatey));
      }
    }
  }
}
</style>
